import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchData } from "../api/fetch";
import { IReply, repliesStateWithModal } from "../core/settings";
import { urlApi } from "../core/url";

const initialState: repliesStateWithModal = {
  items: [],
  visible: false,
  loading: false,
};

export const getReplies = createAsyncThunk(
  "repliesTemp/getReplies",
  async () => {
    const response = await fetchData({
      method: "GET",
      url: `${urlApi}snippet`,
    });
    return response[0].snippets;
  }
);

export const setReply = createAsyncThunk(
  "repliesTemp/setReply",
  async (reply: IReply) => {
    const response = await fetchData({
      method: "PATCH",
      url: `${urlApi}snippet/${reply.id}`,
      data: {
        title: reply.title,
        description: reply.description,
      },
    });
    return response;
  }
);

export const deleteReply = createAsyncThunk(
  "repliesTemp/deleteReply",
  async (id: number) => {
    const response = await fetchData({
      method: "DELETE",
      url: `${urlApi}snippet/${id}`,
    });
    return response;
  }
);

export const addNewReply = createAsyncThunk(
  "repliesTemp/addNewReply",
  async (item: { title: string; description: string }) => {
    const response = await fetchData({
      method: "POST",
      url: `${urlApi}snippet`,
      data: item,
    });
    return response;
  }
);

export const repliesTempSlice = createSlice({
  name: "repliesTemp",
  initialState,
  reducers: {
    setVisible: (state, action: PayloadAction<boolean>) => {
      state.visible = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getReplies.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getReplies.fulfilled, (state, action) => {
        state.items = action.payload;
        state.loading = false;
      })
      .addCase(getReplies.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(setReply.fulfilled, (state, action) => {
        const idx = state.items.findIndex(
          (item) => item.id === action.payload.id
        );
        state.items.splice(idx, 1, action.payload);
      })
      .addCase(deleteReply.fulfilled, (state, action) => {
        state.items = state.items.filter(
          (item) => item.title !== action.payload.title
        );
      })
      .addCase(addNewReply.fulfilled, (state, action) => {
        state.items.push(action.payload);
        state.visible = false;
      });
  },
});

export const { setVisible } = repliesTempSlice.actions;

export default repliesTempSlice.reducer;

import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import authReducer from "./redux/authSlice";
import adminSettingsReducer from "./redux/adminSettingsSlice";
import dashboardReducer from "./redux/dashboardSlice";
import settingsReducer from "./redux/settingsSlice";
import repliesReducer from "./redux/repliesSlice";
import dashboardManagerReducer from "./redux/managerDashboardSlice";

export const store = configureStore({
  reducer: {
    user: authReducer,
    adminSettings: adminSettingsReducer,
    dashboard: dashboardReducer,
    settings: settingsReducer,
    repliesTemp: repliesReducer,
    dashboardManager: dashboardManagerReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

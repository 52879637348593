import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { dataRow, ISettings } from "../core/settings";
import { axiosInstance, fetchData } from "../api/fetch";
import { urlApi } from "../core/url";

//  !!!!!!!!!!!!!!!!!!!!!!!!!!!!
const initialState: ISettings = {
  row: {
    0: {
      id: 0,
      username: "",
      email: "",
      disable: false,
      is_admin: false,
    },
  },
  visible: false,
  loading: false,
};

export const getManagers = createAsyncThunk(
  "settings/fetchManagers",
  async () => {
    const response = await axiosInstance().get("users");
    return response.data;
  }
);

export const setManager = createAsyncThunk(
  "user/setManager",
  async (user: dataRow) => {
    const response = await fetchData({
      method: "POST",
      url: `${urlApi}auth/createuser`,
      data: { ...user, is_admin: false },
    });
    return response;
  }
);

export const editManager = createAsyncThunk(
  "user/editManager",
  async (user: dataRow) => {
    const response = await fetchData({
      method: "PATCH",
      url: `${urlApi}users/${user.id}`,
      data: {
        username: user.username,
        email: user.email,
        disable: !user.disable ? "Active" : "Disabled",
        password: user.password,
      },
    });
    return response;
  }
);

export const deleteManager = createAsyncThunk(
  "user/deleteManager",
  async (id: number) => {
    const { data } = await axiosInstance().delete(`users/${id}`);
    return data;
  }
);

export const disableManager = createAsyncThunk(
  "user/disableManager",
  async ({ id, disable }: any) => {
    const { data } = await axiosInstance().patch(`users/disable/${id}`, {
      disable,
    });
    return data;
  }
);

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setVisible: (state, action: PayloadAction<boolean>) => {
      state.visible = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getManagers.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getManagers.fulfilled, (state, action) => {
        state.row = action.payload;
        state.loading = false;
      })
      .addCase(getManagers.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(disableManager.fulfilled, (state, action: any) => {
        state.row = {
          ...state.row,
          [action.payload.id]: action.payload,
        };
      })
      .addCase(disableManager.rejected, (state, action) => {})
      .addCase(deleteManager.fulfilled, (state, action) => {
        state.row = action.payload;
      })
      .addCase(setManager.fulfilled, (state, action) => {
        state.row = action.payload;
      })
      .addCase(editManager.fulfilled, (state, action) => {
        state.row = action.payload;
      });
  },
});

export const { setVisible } = settingsSlice.actions;

export default settingsSlice.reducer;

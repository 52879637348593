import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { authObject, data } from "../core/auth";
import { axiosInstance } from "../api/fetch";
import { localSt } from "../utils/localObject";

const initialState: authObject = {
  data: {
    status: false,
    token: "",
    name: "",
    email: "",
    id: 0,
    email_verified_at: "",
    created_at: "",
    updated_at: "",
    role: "",
  },
  isAuth: false,
  loading: false,
};

export const getUserObject = createAsyncThunk(
  "user/fetchData",
  async (user: data) => {
    const response = await axiosInstance().post("auth/login", user);
    return response.data;
  }
);

export const authSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    login: (state) => {
      state.isAuth = true;
    },
    logout: (state) => {
      state.isAuth = false;
    },
    userLog: (state, action) => {
      state.data.token = action.payload;
    },
    userLoggedIn: (state, action) => {
      state.isAuth = action.payload;
    },
    userRole: (state, action) => {
      state.data.role = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserObject.pending, (state) => {
        state.data.status = true;
      })
      .addCase(getUserObject.fulfilled, (state, action) => {
        state.data.status = false;
        state.data = action.payload;
        if (action.payload.token) {
          state.isAuth = true;
          localSt.setItemLocal("TOKEN", action.payload.token);
          localSt.setItemLocal("Name", action.payload.username);
          localSt.setItemLocal("Role", action.payload.is_admin);
          localSt.setItemLocal("ID", action.payload.id);
        }
      })
      .addCase(getUserObject.rejected, (state, action) => {
        state.data.status = false;
        state.isAuth = false;
      });
  },
});

export const { userLog, userLoggedIn, userRole, login, logout } =
  authSlice.actions;

export default authSlice.reducer;

const routing = {
  MAIN: "/",
  LOGIN: "/login",
  FORGOT_PASSWORD: "/forgot",
  ADMIN_SETTINGS: "/admin-settings",
  MANAGER_SETTINGS: "/settings",
  MANAGERS_TABLE: "/managers-settings",
  SETTINGS: "/settings",
  MANAGER_DASHBOARD: "/manager-dashboard",
  FORGET: "/forget-password",
};

export default routing;

import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../core/hooks";
import { Keys } from "../../core/layout";
import { logout } from "../../redux/authSlice";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { Layout, Menu, Dropdown, Button } from "antd";
import {
  PieChartOutlined,
  UserOutlined,
  DownOutlined,
  UpOutlined,
  MenuOutlined,
} from "@ant-design/icons";

import styles from "./Layout.module.scss";
import "antd/dist/antd.css";
import { localSt } from "../../utils/localObject";
import routing from "../../utils/routes";
import { getManagers } from "../../redux/settingsSlice";

const { Sider } = Layout;
const { SubMenu } = Menu;
const { Header } = Layout;

const is_admin = localSt.getItemLocal("Role");

const isAdmin =
  typeof is_admin === "string" ? localSt.isAdminRole(is_admin) : {};

const Sidebar = () => {
  return (
    <Sider
      width={250}
      className={styles.containerSider}
      collapsible
      defaultCollapsed
    >
      <div className="logo" />

      <Menu
        className={styles.containerSider_menu}
        style={{}}
        theme="dark"
        mode="inline"
      >
        <Menu.Item
          className={styles.containerSider_menu_item}
          icon={<PieChartOutlined />}
          key={Keys.Zero}
        >
          <Link to="/" className={styles.containerSider_menu_item_link}>
            Dashboard
          </Link>
        </Menu.Item>

        <SubMenu
          className={styles.containerSider_menu_submenu}
          icon={<UserOutlined />}
          title="Пользователь"
          key={Keys.One}
        >
          {isAdmin && (
            <>
              <Menu.Item
                className={styles.containerSider_menu_submenu_item}
                key={Keys.Seven}
              >
                <Link to="/managers-settings">Все менеджеры</Link>
              </Menu.Item>
              <Menu.Item
                className={styles.containerSider_menu_submenu_item}
                key={Keys.Two}
              >
                <Link to="/admin-settings">Настройки админа</Link>
              </Menu.Item>
            </>
          )}
          <Menu.Item
            className={styles.containerSider_menu_submenu_item}
            key={Keys.Three}
          >
            <Link to="/settings">Настройки пользователя</Link>
          </Menu.Item>
        </SubMenu>
      </Menu>
    </Sider>
  );
};

const BurgerMenu = () => {
  const name = localSt.getItemLocal("Name");
  const dispatch = useAppDispatch();
  const history = useHistory();

  const menu = (
    <Menu>
      <Menu.Item key={Keys.Four}>
        <Link to="/">Dashboard</Link>
      </Menu.Item>
      {isAdmin && (
        <>
          <Menu.Item key={Keys.Five}>
            <Link to="/admin-settings">Настройки админа</Link>
          </Menu.Item>
          <Menu.Item key={Keys.Eight}>
            <Link to="/managers-settings">Все менеджеры</Link>
          </Menu.Item>
          <Menu.Item key={Keys.Eight}>
            <Link to="/settings">Настройки пользователя</Link>
          </Menu.Item>
        </>
      )}
    </Menu>
  );
  const Logout = (
    <Menu className={styles.container_logout}>
      {!isAdmin && (
        <Menu.Item key={Keys.Seven}>
          <Link to="/settings">
            <Button type="text">Настройки</Button>
          </Link>
        </Menu.Item>
      )}
      <Menu.Item key={Keys.Eight}>
        <Button
          type="text"
          onClick={() => {
            dispatch(logout());
            localSt.removeItemLocal("TOKEN");
            localSt.removeItemLocal("Role");
            localSt.removeItemLocal("Name");
            localSt.removeItemLocal("ID");
            history.push(routing.LOGIN);
          }}
        >
          logout
        </Button>
      </Menu.Item>
    </Menu>
  );
  return (
    <div className={styles.burgerMenu}>
      {isAdmin && (
        <Dropdown
          overlay={menu}
          trigger={["click"]}
          className={styles.dropdown}
        >
          <MenuOutlined />
          {/* <button
            className={styles.burgerMenu_btn}
            onClick={(e) => e.preventDefault()}
          ></button> */}
        </Dropdown>
      )}
      <div></div>
      <Dropdown
        className={styles.nameAdmin}
        overlay={Logout}
        trigger={["click"]}
        placement="topRight"
      >
        <Link
          to="/login"
          className={styles.containerHeader_logoutLink}
          onClick={(e) => e.preventDefault()}
        >
          {name} <DownOutlined />
        </Link>
      </Dropdown>
    </div>
  );
};

const HeaderPart = () => {
  const name = localSt.getItemLocal("Name");
  const dispatch = useAppDispatch();
  const history = useHistory();

  const Logout = (
    <Menu className={styles.logout}>
      {!isAdmin && (
        <Menu.Item key={Keys.Nine}>
          <Link to="/settings">
            <Button type="text">Настройки</Button>
          </Link>
        </Menu.Item>
      )}
      <Menu.Item key={Keys.Ten}>
        <Button
          type="link"
          onClick={() => {
            dispatch(logout());
            localSt.removeItemLocal("TOKEN");
            localSt.removeItemLocal("Role");
            localSt.removeItemLocal("Name");
            localSt.removeItemLocal("ID");
            history.push(routing.LOGIN);
          }}
        >
          Logout
        </Button>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className={styles.containerHeader}>
      <Header className={styles.containerHeader_header}>
        <Dropdown
          className={styles.nameAdmin}
          overlay={Logout}
          trigger={["click"]}
        >
          <Link
            to="/login"
            className={styles.containerHeader_logoutLink}
            onClick={(e) => e.preventDefault()}
          >
            {name} <DownOutlined />
          </Link>
        </Dropdown>
      </Header>
    </div>
  );
};

const LayoutMain: React.FC = ({ children }) => {
  const { isAuth } = useAppSelector((state) => state.user);

  const history = useHistory();
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (isAuth) {
      if (
        (isAdmin && history.location.pathname === routing.LOGIN) ||
        (isAdmin && history.location.pathname === routing.MANAGER_DASHBOARD)
      ) {
        dispatch(getManagers());
        history.push(routing.MAIN);
      } else if (
        (!isAdmin && history.location.pathname === routing.LOGIN) ||
        (!isAdmin && history.location.pathname === routing.MAIN)
      ) {
        history.push(routing.MANAGER_DASHBOARD);
      }
    }
  }, [dispatch, history, isAuth]);

  return (
    <div className={styles.layout}>
      {isAuth && isAdmin && (
        <div className={styles.layout_sidebar}>
          <div className={styles.layout_sidebar_wrapper}>
            <Sidebar />
          </div>
        </div>
      )}
      <div className={styles.layout_mainView}>
        {isAuth && (
          <div>
            <HeaderPart />
            <BurgerMenu />
          </div>
        )}
        <div className={styles.layout_mainView_content}>{children}</div>
      </div>
      <span className={styles.layout_btnUpWrapper}>
        <Button
          className={styles.layout_btnUpWrapper_btn}
          onClick={() => {
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
          icon={<UpOutlined />}
        />
      </span>
    </div>
  );
};

export default LayoutMain;

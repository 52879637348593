import { axiosInstance } from "./../api/fetch";
import {
  adminSettingsObject,
  keywordsObject,
  deleteKeyword,
  countriesObject,
  minPropObject,
} from "../core/adminSettings";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState: adminSettingsObject = {
  dangers: [],
  keywords: [],
  minProposals: [],
  loading: false,
};

export const getCountriesList = createAsyncThunk(
  "adminSettings/fetchCountries",
  async () => {
    const response = await axiosInstance().get("locations");

    return response.data;
  }
);

export const setCountry = createAsyncThunk(
  "adminSettings/setCountry",
  async (countries: countriesObject) => {
    const response = await axiosInstance().patch(
      `locations/${countries.id}`,
      countries
    );

    return response.data;
  }
);

export const getKeywordsList = createAsyncThunk(
  "adminSettings/fetchKeywords",
  async () => {
    const response = await axiosInstance().get("keyword");

    return response.data;
  }
);

export const setKeywordsList = createAsyncThunk(
  "adminSettings/setKeywords",
  async (keywords: keywordsObject) => {
    const response = await axiosInstance().post("keyword", keywords);

    return response.data;
  }
);

export const removeKeyword = createAsyncThunk(
  "adminSettings/removeKeyword",
  async (id: deleteKeyword) => {
    const response = await axiosInstance().delete(`keyword/${id.id}`);

    return response.data;
  }
);

export const getMinProp = createAsyncThunk(
  "adminSettings/fetchMinProp",
  async () => {
    const response = await axiosInstance().get("setting");

    return response.data;
  }
);

export const setMinProp = createAsyncThunk(
  "adminSettings/setMinProp",
  async (minProp: minPropObject) => {
    const response = await axiosInstance().patch(`setting/${minProp.id}`, {
      value: minProp.value,
    });

    return response.data;
  }
);

export const adminSettingsSlice = createSlice({
  name: "adminSettings",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCountriesList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCountriesList.fulfilled, (state, action) => {
        state.dangers = action.payload;
        state.loading = false;
      })
      .addCase(setCountry.pending, (state) => {
        state.loading = true;
      })
      .addCase(setCountry.fulfilled, (state, action) => {
        state.dangers = action.payload;
        state.loading = false;
      })
      .addCase(getKeywordsList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getKeywordsList.fulfilled, (state, action) => {
        state.loading = false;
        state.keywords = action.payload;
      })
      .addCase(setKeywordsList.fulfilled, (state, action) => {
        state.keywords.push(action.payload);
      })
      .addCase(removeKeyword.pending, (state) => {
        state.loading = true;
      })
      .addCase(removeKeyword.fulfilled, (state, action) => {
        state.loading = false;
        state.keywords = state.keywords.filter(
          (item) => item.content !== action.payload.content
        );
      })
      .addCase(getMinProp.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getMinProp.fulfilled, (state, action) => {
        state.minProposals = action.payload;
        state.loading = false;
      })
      .addCase(setMinProp.fulfilled, (state, action) => {
        state.minProposals[0] = action.payload;
      });
  },
});

export default adminSettingsSlice.reducer;

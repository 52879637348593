import {
  dashboardObject,
  numProp,
  propDayObject,
  propDayDescObject,
  dateIntervalObject,
  dateObject,
} from "../core/dashboard";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchData } from "../api/fetch";
import { urlApi } from "../core/url";
import { oneManagerDayObject } from "../core/managerDashboard";

const initialState: dashboardObject = {
  totalMonth: 0,
  maxPropMonth: 0,
  totalDay: 0,
  maxPropDay: 0,
  readyProp: false,
  readyDayProp: false,
  propForMonth: 0,
  propsForDay: 0,
  bestDay: [
    {
      user_username: "",
      proposal_status: 0,
      proposal_id: 0,
    },
  ],
  bestMonth: [
    {
      user_username: "",
      proposal_status: 0,
      proposal_id: 0,
    },
  ],
  infoProposals: [
    {
      user_username: "",
      proposal_status: 0,
      proposal_id: 0,
    },
  ],
  infoProposalsRange: [
    {
      user_username: "",
      proposal_status: 0,
      proposal_id: 0,
    },
  ],
  propYear: [
    {
      user: {
        id: 0,
        name: "",
      },
      month: {
        January: 0,
        February: 0,
        March: 0,
        April: 0,
        May: 0,
        June: 0,
        July: 0,
        August: 0,
        September: 0,
        October: 0,
        November: 0,
        December: 0,
      },
    },
  ],
  dayInfo: [
    {
      month: "",
      count: "",
    },
    {
      userId: 0,
      date: "",
      count: "",
    },
  ],
  propDayDesc: [
    {
      Proposals_id: 0,
      Proposals_url: "",
      Proposals_location: "",
      Proposals_title: "",
      Proposals_description: "",
      Proposals_total_charges_amount: 0,
      Proposals_total_charges_currency: "",
      Proposals_budget_amount: 0,
      Proposals_budget_currency: "",
      Proposals_reply: "",
      Proposals_status: 0,
      Proposals_created_at: "",
      Proposals_userId: 0,
      user_username: "",
    },
  ],
  selectedYear: null,
};

export const getDataManager = createAsyncThunk(
  "dashboard/fetchDataManager",
  async () => {
    const response = await fetchData({ method: "GET", url: `${urlApi}user` });
    return response;
  }
);

export const getAllProposals = createAsyncThunk(
  "dashboard/fetchAllProposals",
  async () => {
    const response = await fetchData({
      method: "GET",
      url: `${urlApi}proposals`,
    });

    return response;
  }
);

export const getProposals = createAsyncThunk(
  "dashboard/fetchProposals",
  async () => {
    const response = await fetchData({
      method: "GET",
      url: `${urlApi}proposals/bydate`,
    });

    return response;
  }
);

export const getProposalsYear = createAsyncThunk(
  "dashboard/fetchProposalsYear",
  async ({ year }: { year: number }) => {
    const response = await fetchData({
      method: "GET",
      url: `${urlApi}proposals/month?year=${year}`,
    });

    return response;
  }
);

export const getProposalsDay = createAsyncThunk(
  "dashboard/fetchProposalsDay",
  async ({ id, monthName, year }: propDayObject) => {
    const response = await fetchData({
      method: "GET",
      url: `${urlApi}proposals/day?user_id=${id}&month=${monthName}&year=${year}`,
    });

    return response;
  }
);

export const getPropInterval = createAsyncThunk(
  "dashboard/fetchPropInterval",
  async ({ start, end }: dateObject) => {
    const response = await fetchData({
      method: "GET",
      url: `${urlApi}proposals/byinterval?after=${start}&before=${end}`,
    });
    return response;
  }
);

export const getOneManagerForDay = createAsyncThunk(
  "dashboard/fetchOneManagerForDay",
  async ({ id, today }: oneManagerDayObject) => {
    const response = await fetchData({
      method: "GET",
      url: `${urlApi}proposals/bydatewithuserid?user_id=${id}&date=${today}`,
    });
    return response;
  }
);

export const getPropIntervalForMonth = createAsyncThunk(
  "dashboard/fetchPropIntervalForMonth",
  async ({ startOfMonth, endOfMonth }: dateIntervalObject) => {
    const response = await fetchData({
      method: "GET",
      url: `${urlApi}proposals/byinterval?after=${startOfMonth}&before=${endOfMonth}`,
    });
    return response;
  }
);

export const getProposalsDayDesc = createAsyncThunk(
  "dashboard/fetchProposalsDayDecs",
  async ({
    id,
    newPointYear,
    newPointMonth,
    newPointDay,
  }: propDayDescObject) => {
    const response = await fetchData({
      method: "GET",
      url: `${urlApi}proposals/bydatewithuserid?user_id=${id}&date=${newPointYear}.${newPointMonth}.${newPointDay}`,
    });

    return response;
  }
);

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProposals.fulfilled, (state, action) => {
        const numb = action.payload.filter(
          (item: numProp) => item.proposal_status === 1
        ).length;
        state.propsForDay = numb;
        state.bestDay = action.payload;
      })
      .addCase(getProposalsYear.pending, (state) => {
        state.readyProp = false;
        state.selectedYear = null;
      })
      .addCase(getProposalsYear.rejected, (state, action) => {
        state.readyProp = false;
      })
      .addCase(getProposalsYear.fulfilled, (state, action) => {
        state.propYear = action.payload;
        state.selectedYear = action.meta.arg.year;
        state.readyProp = true;
      })
      .addCase(getProposalsDayDesc.fulfilled, (state, action) => {
        state.propDayDesc = action.payload;
      })
      .addCase(getProposalsDay.pending, (state) => {
        state.readyDayProp = false;
      })
      .addCase(getProposalsDay.fulfilled, (state, action) => {
        state.dayInfo = action.payload;
        state.readyDayProp = true;
      })
      .addCase(getPropInterval.fulfilled, (state, action) => {
        state.infoProposalsRange = action.payload;
      })
      .addCase(getPropIntervalForMonth.fulfilled, (state, action) => {
        state.propForMonth =
          action.payload.filter((item: numProp) => item.proposal_status === 1)
            .length || 0;
        state.bestMonth = action.payload;
        state.infoProposals = action.payload;
      });
  },
});

export default dashboardSlice.reducer;

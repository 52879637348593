import {
  itemObject,
  managerDashboardObject,
  oneManagerDayObject,
  oneManagerObject,
} from "../core/managerDashboard";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchData } from "../api/fetch";
import { urlApi } from "../core/url";

const initialState: managerDashboardObject = {
  totalDayManager: null,
  totalMonthManager: null,
  managerInfoSubmitted: [
    {
      Proposals_id: 0,
      Proposals_url: "",
      Proposals_location: "",
      Proposals_title: "",
      Proposals_description: "",
      Proposals_total_charges_amount: 0,
      Proposals_total_charges_currency: "",
      Proposals_budget_amount: 0,
      Proposals_budget_currency: "",
      Proposals_reply: "",
      Proposals_status: false,
      Proposals_created_at: "",
      Proposals_userId: 0,
    },
  ],
  managerInfo: [
    {
      Proposals_id: 0,
      Proposals_url: "",
      Proposals_location: "",
      Proposals_title: "",
      Proposals_description: "",
      Proposals_total_charges_amount: 0,
      Proposals_total_charges_currency: "",
      Proposals_budget_amount: 0,
      Proposals_budget_currency: "",
      Proposals_reply: "",
      Proposals_status: false,
      Proposals_created_at: "",
      Proposals_userId: 0,
    },
  ],
};

export const getOneManagerForDay = createAsyncThunk(
  "dashboard/fetchOneManagerForDay",
  async ({ id, today }: oneManagerDayObject) => {
    const response = await fetchData({
      method: "GET",
      url: `${urlApi}proposals/bydatewithuserid?user_id=${id}&date=${today}`,
    });
    return response;
  }
);

export const getOneManagerRange = createAsyncThunk(
  "dashboard/fetchOneManagerRange",
  async ({ id, startOfMonth, endOfMonth }: oneManagerObject) => {
    const response = await fetchData({
      method: "GET",
      url: `${urlApi}proposals/byintervalwithuserid?user_id=${id}&after=${startOfMonth}&before=${endOfMonth}`,
    });

    return response;
  }
);

export const dashboardManagerSlice = createSlice({
  name: "dashboardManager",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOneManagerForDay.fulfilled, (state, action) => {
        const submited = action.payload.filter(
          (item: itemObject) => item.Proposals_status === 1
        );

        if (state.totalDayManager === null) {
          state.totalDayManager = submited.length;
        }

        state.managerInfoSubmitted = submited;

        state.managerInfo = action.payload.filter(
          (item: itemObject) => item.Proposals_status === 0
        );
      })
      .addCase(getOneManagerRange.fulfilled, (state, action) => {
        const submited = action.payload.filter(
          (item: itemObject) => item.Proposals_status === 1
        );

        if (state.totalMonthManager === null) {
          state.totalMonthManager = submited.length;
        }

        state.managerInfoSubmitted = submited;

        state.managerInfo = action.payload.filter(
          (item: itemObject) => item.Proposals_status === 0
        );
      });
  },
});

export default dashboardManagerSlice.reducer;

import { authData } from "../core/auth";
import { localSt } from "../utils/localObject";
import axios from "axios";
import { notification } from "antd";
import routing from "../utils/routes";

export const axiosInstance = () => {
  const errorObj: any = {
    401: (error: any) => {
      notification.error({ message: error.response.data.message });

      localStorage.removeItem("TOKEN");
      if (window.location.pathname !== routing.LOGIN) {
        window.location.href = routing.LOGIN;
      }
    },
    400: (error: any) => {
      notification.error({ message: error.response.data.message });
    },
    403: (error: any) => {
      notification.error({ message: error.response.data.message });
    },
    500: (error: any) => {
      console.log(error);
    },
  };
  const defaultOptions = {
    baseURL: process.env.REACT_APP_URL,
    headers: {
      "Content-Type": "application/json",
    },
  };

  const instance = axios.create(defaultOptions);

  instance.interceptors.request.use(function (config) {
    const token = localStorage.getItem("TOKEN");
    if (token) {
      config.headers = { Authorization: `Bearer ${token}` };
    }
    return config;
  });

  instance.interceptors.response.use(
    (response) => {
      return response;
    },

    (error) => {
      console.log(error);
      errorObj[error.response.status](error);
      return Promise.reject(error);
    }
  );

  return instance;
};

const headerCheck = (): HeadersInit => {
  const checkToken = localSt.getItemLocal("TOKEN");
  if (!!checkToken) {
    return {
      "Content-Type": "application/json",
      Authorization: `Bearer ${checkToken}`,
    };
  }

  return {
    "Content-Type": "application/json",
  };
};

export function fetchData({ method, url, data }: authData) {
  const headers = new Headers(headerCheck());
  const body = JSON.stringify(data as BodyInit | null | undefined);
  const option = { method, headers, body };

  return fetch(url, option)
    .then((res) => {
      try {
        if (res.status === 500) throw Error();
        return res.json();
      } catch {
        throw Error();
      }
    })
    .catch((err) => {
      throw Error(err);
    });
}

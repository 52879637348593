export const localSt = {
  getItemLocal: function (item: string) {
    return localStorage.getItem(item);
  },
  removeItemLocal: function (item: string) {
    return localStorage.removeItem(item);
  },
  setItemLocal: function (itemName: string, item: string) {
    return localStorage.setItem(itemName, item);
  },
  isAdminRole: function (item: string) {
    return item === "true";
  },
};

import React, { useEffect } from "react";
import { Route, useHistory } from "react-router-dom";
import { IPrivateRoute } from "../core/route";
import { useAppDispatch } from "../core/hooks";
import { login } from "../redux/authSlice";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { localSt } from "./localObject";
import routing from "./routes";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const PrivateRoute: React.FC<IPrivateRoute> = ({
  children,
  isAuth,
  loading,
  ...rest
}) => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  useEffect(() => {
    if (localSt.getItemLocal("TOKEN")) {
      dispatch(login());
    } else {
      history.push(routing.LOGIN);
    }
  }, [isAuth, dispatch, history]);

  return !loading ? (
    <Route {...rest} render={() => children} />
  ) : (
    <Spin
      indicator={antIcon}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    />
  );
};
export default PrivateRoute;

import React, { lazy, Suspense } from "react";
import routing from "../utils/routes";
import PrivateRoute from "../utils/PrivateRoute";
import LayoutMain from "../containers/Layout/Layout";
import { IRouteObject, IRouteRole } from "../core/route";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useAppSelector } from "../core/hooks";
import styles from "./routing.module.scss";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { localSt } from "../utils/localObject";

const route: IRouteObject = {
  dashboard: {
    path: routing.MAIN,
    exact: true,
    component: lazy(
      () => import("../containers/DashboardContainer/DashboardContainer")
    ),
  },
  adminSettings: {
    path: routing.ADMIN_SETTINGS,
    exact: true,
    component: lazy(
      () =>
        import("../containers/AdminSettingsContainer/AdminSettingsContainer")
    ),
  },
  settings: {
    path: routing.SETTINGS,
    exact: true,
    component: lazy(
      () => import("../containers/SettingsContainer/SettingsContainer")
    ),
  },
  auth: {
    path: routing.LOGIN,
    exact: true,
    component: lazy(() => import("../containers/AuthContainer/AuthContainer")),
  },
  managerSettings: {
    path: routing.MANAGER_SETTINGS,
    exact: true,
    component: lazy(
      () => import("../containers/SettingsContainer/SettingsContainer")
    ),
  },
  managersTable: {
    path: routing.MANAGERS_TABLE,
    exact: true,
    component: lazy(
      () =>
        import("../containers/ManagersTableContainer/ManagersTableContainer")
    ),
  },
  managerDashboard: {
    path: routing.MANAGER_DASHBOARD,
    exact: true,
    component: lazy(
      () =>
        import(
          "../containers/ManagerDashboardContainer/ManagerDashboardContainer"
        )
    ),
  },
  forget: {
    path: routing.FORGET,
    exact: true,
    component: lazy(() => import("../containers/Forget/ForgetContainer")),
  },
};

const domain: IRouteRole = {
  forget: [route.forget],
  admin: [
    route.dashboard,
    route.adminSettings,
    route.auth,
    route.settings,
    route.managersTable,
  ],
  manager: [route.managerDashboard, route.settings, route.auth],
};

const antIcon = <LoadingOutlined className={styles.loadingOutlined} spin />;

const RoutesSystem = () => {
  const { loading, isAuth } = useAppSelector((state) => state.user);
  const determRole = localSt.getItemLocal("Role");
  const isAdmin = !!(determRole === "true");

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <Suspense fallback={<Spin indicator={antIcon} className={styles.spin} />}>
        <Router>
          <Switch>
            <LayoutMain>
              {isAdmin &&
                domain.admin.map((route, index) => (
                  <PrivateRoute
                    isAuth={isAuth}
                    loading={loading}
                    key={index}
                    path={route.path}
                    exact={route.exact}
                  >
                    <route.component />
                  </PrivateRoute>
                ))}
              {!isAdmin &&
                domain.manager.map((route, index) => (
                  <PrivateRoute
                    isAuth={isAuth}
                    loading={loading}
                    key={index}
                    path={route.path}
                    exact={route.exact}
                  >
                    <route.component />
                  </PrivateRoute>
                ))}
              {!isAuth &&
                domain.forget.map((route, index) => (
                  <Route path={route.path} exact={route.exact} key={index}>
                    <route.component />
                  </Route>
                ))}
            </LayoutMain>
          </Switch>
        </Router>
      </Suspense>
    </div>
  );
};

export default RoutesSystem;
